import { track } from '$extensionSrc/metrics/track'

import { getAttributionData, updateLinks } from './utils/attribution'
import { applyTypewriterEffect } from './utils/typewriter-effect'

window.addEventListener('DOMContentLoaded', () => {
  //
  // Site-wide code
  //

  // Track page view and include attribution data (UTM params, referrer, etc.)
  track({
    name: 'page:view',
    properties: getAttributionData(),
  }).then(() => {
    // This adds attribution data as querystring parameters to
    // privacypartyapp.com links
    updateLinks()
  })

  // Track every link click
  document.querySelectorAll('a').forEach((link) => {
    link.addEventListener('click', () => {
      track({
        name: 'link:click',
        properties: {
          elementId: link.id,
          linkUrl: link.href,
          textContent: link.innerText,
        },
      })
    })
  })

  //
  // Page-specific code
  //

  const currentPath = window.location.pathname

  switch (currentPath) {
    case '/':
      applyTypewriterEffect(
        'typewriter-box',
        'typewriter-cursor',
        ['your ex', 'your boss', 'a stalker', 'trolls', 'scammers'],
        (element: HTMLElement) => {
          // eslint-disable-next-line no-param-reassign
          element.style.borderRight = '1px solid #7BB7CF'
        },
        (element: HTMLElement) => {
          // eslint-disable-next-line no-param-reassign
          element.style.borderRight = '1px solid #FFFFFF'
        },
        {
          characterWaitMs: 100,
          textWaitMs: 2000,
          cursorAlternateMs: 500,
        },
      )
      break
    default:
      break
  }
})
