import {
  getCookieIgnoreHostPermissionErrors,
  removeCookieIgnoreHostPermissionErrors,
} from './cookies'
import { GobyApiCookieType } from './enums'
import { apiOrigin } from '../consts/urlConsts'

const CSRF_HEADER = 'X-CSRF-TOKEN'

export async function getAccessToken(): Promise<string | undefined> {
  if (!apiOrigin) {
    return undefined
  }

  const cookie = await getCookieIgnoreHostPermissionErrors({
    name: GobyApiCookieType.ACCESS_JWT,
    url: apiOrigin,
  })

  return cookie?.value
}

export async function getRefreshToken(): Promise<string | undefined> {
  if (!apiOrigin) {
    return undefined
  }

  const cookie = await getCookieIgnoreHostPermissionErrors({
    name: GobyApiCookieType.REFRESH_JWT,
    url: apiOrigin,
  })

  return cookie?.value
}

export async function getAccessTokenCsrf(): Promise<string | undefined> {
  if (!apiOrigin) {
    return undefined
  }

  const cookie = await getCookieIgnoreHostPermissionErrors({
    name: GobyApiCookieType.ACCESS_JWT_CSRF,
    url: apiOrigin,
  })

  return cookie?.value
}

export async function getRefreshTokenCsrf(): Promise<string | undefined> {
  if (!apiOrigin) {
    return undefined
  }

  const cookie = await getCookieIgnoreHostPermissionErrors({
    name: GobyApiCookieType.REFRESH_JWT_CSRF,
    url: apiOrigin,
  })

  return cookie?.value
}

export async function removeAccessToken(): Promise<void> {
  if (!apiOrigin) {
    return
  }

  await removeCookieIgnoreHostPermissionErrors({
    name: GobyApiCookieType.ACCESS_JWT,
    url: apiOrigin,
  })
}

export async function removeRefreshToken(): Promise<void> {
  if (!apiOrigin) {
    return
  }

  await removeCookieIgnoreHostPermissionErrors({
    name: GobyApiCookieType.REFRESH_JWT,
    url: apiOrigin,
  })
}

export async function getJwtCsrfHeader(
  tokenType: 'access' | 'refresh',
): Promise<[string, string] | [null, null]> {
  const token =
    tokenType === 'access'
      ? await getAccessTokenCsrf()
      : await getRefreshTokenCsrf()

  if (!token) {
    return [null, null]
  }

  return [CSRF_HEADER, token]
}
