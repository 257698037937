import { getApiUrl } from '../consts/urlConsts'
import * as cookies from '../utils/cookies'

// Called by getAnonymousUserId in track AND by background-script as a part of
// the Chrome messaging process
export async function getAnonymousUserIdCookie(): Promise<string | undefined> {
  if (typeof chrome === 'undefined' || !chrome.cookies) {
    return undefined
  }
  const anonymousUserIdCookie =
    await cookies.getCookieIgnoreHostPermissionErrors({
      name: 'anonymous_user_id',
      url: getApiUrl(),
    })
  if (!anonymousUserIdCookie) {
    return undefined
  }
  return anonymousUserIdCookie.value
}

// Called by setAnonymousUserId in track AND by background-script as a part of
// the Chrome messaging process
export async function setAnonymousUserIdCookie(
  anonymousUserId: string,
): Promise<void> {
  if (typeof chrome === 'undefined' || !chrome.cookies) {
    return
  }
  await cookies.setCookie({
    name: 'anonymous_user_id',
    sameSite: 'lax',
    secure: true,
    url: getApiUrl(),
    value: anonymousUserId,
  })
}

export async function removeAnonymousUserIdCookie(): Promise<void> {
  if (typeof chrome === 'undefined' || !chrome.cookies) {
    return
  }
  await cookies.removeCookieIgnoreHostPermissionErrors({
    name: 'anonymous_user_id',
    url: getApiUrl(),
  })
}
